<template>
  <div class="make">
    <img
      src="../assets/make/header.jpg"
      class="make-header"
      ref="header"
      id="hdaer"
    />
    <dr-a v-if="img !== ''" :img="img" @close="img = ''" class="ggg" />
    <!-- <div class="news" ref="middle" id="newss">
      <img src="../assets/make/news/tiao2.png" class="img-new" />
      <div class="new-middle">
        <div>
          <img src="../assets/make/news.png" class="news-title" />
        </div>
        <div class="divider">
          <div class="divider-midd"></div>
        </div>

        <dr-news :news="news" @openn="openn"></dr-news>
      </div>
    </div> -->
    <div ref="last" class="last" id="last">
      <!-- <dr-carousel
        v-if="this.carlist.length !== 0"
        :height="height"
        :position="position"
        :list="carlist"
        type="culture"
      >-->
      <!-- <template v-slot:default="slotprops"> -->
      <!-- <div class="container">
            <div
              v-for="(item, index) in carlist"
              :key="index"
              class="try"
              :class="{ cc: carlist.length - 1 !== index }"
      >-->
      <!-- <dr-container
                v-if="item.type === 1"
                :object="item"
                @open="open"
      />-->
      <!-- <dr-container-3 :object="carlist" class="container2" @open="open" /> -->
      <dr-container-2 :object="carlist" class="container2" @open="open" />
      <!-- </div>
      </div>-->
      <!-- </template> -->
      <!-- </dr-carousel> -->
    </div>
    <transition name="fades">
      <dr-news-content
        v-if="object !== ''"
        :object="object"
        :type="'pic'"
        @close="close"
        @img="gg"
      />
    </transition>
    <transition name="fades">
      <dr-news-content
        v-if="newcontent !== ''"
        :newcontent="newcontent"
        @close="close2"
      />
    </transition>
    <div class="navbar">
      <div
        class="navbar-title"
        :class="{ actNavbar: scrollKey === 'a' }"
        @click="go(scroll.a)"
      >
        公司简介
      </div>
      <!-- <div class="navbar-title" :class="{ actNavbar: scrollKey === 'b' }" @click="go(scroll.b)">新闻中心</div> -->
      <div
        class="navbar-title"
        :class="{ actNavbar: scrollKey === 'c' }"
        @click="go(scroll.c)"
      >
        公司环境
      </div>
    </div>
  </div>
</template>
<script>
import DrNewsContent from "../components/DrNewsContent/index.vue";
import DrContainer2 from "../components/DrContainer/second.vue";
// import DrContainer3 from "../components/DrContainer/copy.vue";
// import DrContainer from "../components/DrContainer/index.vue";
// import DrCarousel from "../components/DrCarousel/index.vue";
// import DrNews from "../components/DrNews/index.vue";
import { cultureList } from "../utils/api";
import DrA from "../components/DrA/index.vue";
export default {
  components: {
    // DrCarousel,
    // DrNews,
    // DrContainer,
    // DrContainer3,
    DrContainer2,
    DrNewsContent,
    DrA,
    // ElEmpty,
  },
  data() {
    return {
      height: "892px",
      position: "none",
      object: "",
      carlist: [],
      // scroll: {
      //   a: 67,
      //   b: 675,
      //   c: 909,
      // },
      scroll: {
        a: 0,
        // b: 0,
        c: 0,
      },
      scrollKey: null,
      list: [],
      newcontent: "",
      listd: [[], []],
      img: "",
    };
  },
  created() {
    cultureList().then((res) => {
      let ccc = res.data.data.list;
      // let a = 0;
      // let active = 0;
      // while (ccc.length !== 0) {
      //   active = a === 2 ? this.change(active) : active;
      //   // console.warn(a, active);
      //   let c = ccc.shift();

      //   this.listd[active].push(c);

      //   a = a === 2 ? 0 : a;

      //   a += c.module;
      // }
      this.carlist = ccc;
      // console.warn(this.carlist, "ggggg");
    });
  },
  mounted() {
    window.scrollTo({
      //滚动到元素位置
      top: 0,
      behavior: "smooth", // 平滑滚动
    });

    //监听滚动
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.dddd());
    // console.warn(this.scroll,"scroll")
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.dddd());
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    dddd() {
      let a = setTimeout(() => {
        this.scroll.a = this.getS(document.getElementById("hdaer"));
        // this.scroll.b = this.getS(document.getElementById("newss"))
        this.scroll.c = this.getS(document.getElementById("last"));
        this.handleScroll();
        console.warn(this.scroll, "scroll");
        clearTimeout(a);
      }, 1000);
    },
    getS(name) {
      // console.warn(document.documentElement,"docu")
      return name.offsetTop - document.documentElement.scrollTop;
    },
    change(a) {
      return a === 1 ? 0 : 1;
    },
    openn(newcontent) {
      this.newcontent = newcontent;
    },
    close2() {
      this.newcontent = "";
    },
    open(object) {
      this.object = object;
    },
    close() {
      this.object = "";
    },
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // console.warn(scrollTop, this.scroll);
      if (scrollTop < this.scroll.c - 1) {
        this.scrollKey = "a";
      } else if (this.scroll.c - 1 <= scrollTop) {
        this.scrollKey = "c";
      }
    },
    go(index) {
      // console.warn(index);
      window.scrollTo({
        top: index,
        behavior: "smooth", // 平滑滚动
      });
    },
    gg(img) {
      this.img = img;
    },
    offSet(curEle) {
      var totalLeft = null;
      var totalTop = null;
      var par = curEle.offsetParent;
      //首先把自己本身的相加
      totalLeft += curEle.offsetLeft;
      totalTop += curEle.offsetTop;
      //现在开始一级一级往上查找，只要没有遇到body，我们就把父级参照物的边框和偏移相加
      while (par) {
        if (navigator.userAgent.indexOf("MSIE 8.0") === -1) {
          //不是IE8我们才进行累加父级参照物的边框
          totalTop += par.clientTop;
          totalLeft += par.clientLeft;
        }
        //把父级参照物的偏移相加
        totalTop += par.offsetTop;
        totalLeft += par.offsetLeft;
        par = par.offsetParent;
      }
      return { left: totalLeft, top: totalTop };
      //返回一个数组，方便我们使用哦。 }
    },
  },
};
</script>
<style scoped>
.fades-enter-active,
.fades-leave-active {
  transition: opacity 0.5s ease;
}

.emtry {
  width: 915px;
  height: 235px;
  display: flex;
  justify-content: center;
  align-items: centers;
  /* background: rgb(184, 181, 181); */
}

.last {
  width: 100%;
  display: flex;
  justify-content: center;
}
.fades-enter-from,
.fades-leave-to {
  opacity: 0;
}
.make-header {
  width: 100%;
  height: auto;
  display: block;
}


.cc {
  margin-right: 25px;
}
.news {
  position: relative;
  /* display: flex; */
  height: 235px;
  justify-content: center;
  /* transform: translateZ(0); */
  /* transform: translate3d(0, 0, 0); */
  /* height: 229px; */
  /* background: url("../assets/make/news/tiao.png") 100% no-repeat;
  background-size: cover; */
  /* background: #d6d6d6;
  box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, 0.45); */
}
.new-middle {
  width: 100vw;
  position: absolute;
  z-index: 2;
  display: flex;
  height: 235px;
  justify-content: center;
}
.img-new {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 235px;
  z-index: 1;
}
/deep/ .el-carousel__item {
  display: flex;
  justify-content: center;
  align-items: center;
}
.divider {
  height: 229px;
  display: flex;
  align-items: center;
}
.divider-midd {
  width: 2px;
  height: 187px;
  /* background: #1d1d1f; */
  background: linear-gradient(to bottom, #c2c1c1 0%, #5b5b5f 50%, #c2c1c1 100%);
  opacity: 0.3;
  /* margin-top: 50%; */
  line-height: 235px;
  margin-right: 45px;
}
.container {
  width: calc(343px + 690px + 642px);
  height: calc(685px + 80px);
  display: flex;
  /* justify-content: space-between; */
}

.news-title {
  font-size: 24px;
  font-family: HarmonyOS_Sans_SC;
  font-weight: 500;
  color: #212121;
  width: 190px;
  /* width: 124px;
  height: 26px; */
  /* text-align: center;
  line-height: 26px; */
  margin-top: 24px;
  /* margin-left: 402px; */
  position: relative;
  /* transform-origin: 0 0;
  transform: translateZ(0); */
  /* transform: translate3d(0, 0, 0); */
  z-index: 5;
  margin-right: 32px;
}
.actNavbar {
  color: #CC73FF !important;
  position: relative;
}
.actNavbar::before {
  position: absolute;
  display: block;
  content: "";
  left: 15px;
  top: 0px;
  width: 4px;
  height: 24px;
  background: #CC73FF;
}
.title-black {
  position: absolute;
  left: 0;
  top: 0;
  width: 124px;
  height: 26px;
  transform: skew(-20deg);
  background: #CC73FF;
  opacity: 0.6;
}
.title-content {
  /* display: block; */
  position: absolute;
  left: 0;
  top: 0;
  width: 124px;
  height: 26px;
  transform-origin: 0 0;
  font-size: 24px;
  font-family: HarmonyOS_Sans_SC;
  font-weight: 400;
  /* transform: skew(15deg); */
  /* transform: skew(-20deg); */
  /* background: #CC73FF; */
}
/* .news-title::before {
  content: "news";
  display: inline-block;
  position: absolute;
  left: -90px;
  top: -17px;
  font-size: 40px;
  font-family: HarmonyOS_Sans_SC_Black;
  font-weight: 600;
  color: rgba(255, 255, 255, 0);
  transform: skew(-20deg);
  text-transform: uppercase;

  -webkit-text-stroke: 1px #212121;
  z-index: 4;
} */

.make {
  /* height: 235px; */
  width: 100%;
  /* transform: translateZ(0); */
  /* display: flex;
  justify-content: center; */
}

.navbar {
  height: 125px;
  width: 134px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
  position: fixed;
  left: 0;
  top: 177px;
  z-index: 2;
}

.navbar-title {
  text-align: center;
  font-size: 18px;
  font-family: HarmonyOS_Sans_SC;
  font-weight: 400;
  color: #f5f5f7;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.85);
  transition: all 0.5 ease;
  cursor: pointer;
}
</style>