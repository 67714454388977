<!--
 * @Author: Lin Qi Ping
 * @Date: 2021-11-04 11:41:21
 * @LastEditors: Lin Qi Ping
 * @LastEditTime: 2021-11-04 12:07:34
 * @Description: 
-->
<template>
  <div class="fixd">
    <img :src="img" @click="close" />
  </div>
</template>
<script>
export default {
  props: {
    img: {
      type: String,
      default: "",
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style scoped>
.fixd {
  background: black;
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 7000;
  left: 0;
  top: 0;
}
</style>