<template>
  <div class="second" ref="ddd" id="cccc">
    <el-carousel
      :height="height"
      trigger="click"
      v-if="list.length !== 0"
      :loop="false"
    >
      <el-carousel-item
        v-for="(item, index) in list"
        :key="index"
        class="slide"
      >
        <div class="imgdf" @click="open(item)">
          <div class="img-w">
            <img :src="item.cover" class="imgdg" />
            <img src="../../assets/z.png" class="img1" />
          </div>
          <div class="title">
            <div class="title-back">{{ item.en_name }}</div>
            <div class="title-show">{{ item.name }}</div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
import { ElCarousel, ElCarouselItem } from "element-plus";
export default {
  components: {
    ElCarousel,
    ElCarouselItem,
  },
  props: {
    object: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      calleft: [0, 0],
      long: null,
      list: null,
      height: "800px",
      // gg: [0, 0],
    };
  },
  watch: {
    object: {
      handler(val) {
        let list = JSON.parse(JSON.stringify(val));
        this.list = list;
        // console.warn(val,"valfff")
      },
      immediate: true,
    },
  },
  mounted() {
    this.getHieght();
    window.addEventListener("resize", this.getHieght);
    // this.ddd()
    // window.addEventListener("resize", this.ddd)
  },
  unmounted() {
    window.removeEventListener("resize", this.getHieght);
  },
  methods: {
    open(object) {
      this.$emit("open", object);
    },
    getHieght() {
      let height = (window.innerWidth / 1920) * 833;
      // console.warn(this.height);
      this.height = height + "px";
      this.width = window.innerWidth + "px";
    },
  },
};
</script>
<style scoped>
.second {
  padding: 50px 0;
  min-width: 1080px;
  width: 100%;
  background: url("../../assets/bg.jpg") center no-repeat;
}
.height {
  height: auto !important;
}
.img-w {
  position: relative;
  width: 1554px;
  height: 686px;
  overflow: hidden;
}
.img1 {
  position: absolute;
  opacity: 0;
  left: 0;
  top: 0;
  opacity: 0;
  /* height: 297px; */
  width: 100%;
  transition: all 0.5s ease;
  /* z-index: 7; */
}
.imgdf {
  width: 1598px;
  overflow: hidden;
  cursor: pointer;
}
.imgdg {
  width: 100%;
}
.slide {
  padding: 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.second >>> .el-carousel__arrow {
  background: unset;
}
.second >>> .el-icon-arrow-left:before {
  content: "";
  /* background-color: teal; */

  background-image: url("../../assets/home-carousel/left-1.png");
  background-size: cover;
  /* background: url("../assets/home-carousel/头图左箭头-选中.png") fixed no-repeat cover; */
  display: inline-block;
  width: 32px;
  height: 51px;
}

.second >>> .el-icon-arrow-left:hover:before {
  background-image: url("../../assets/home-carousel/left-2.png");
}
.second >>> .el-carousel__indicator--horizontal {
  height: 4px;
}
.second >>> .el-icon-arrow-right:before {
  content: "";
  /* background-color: teal; */

  background-image: url("../../assets/home-carousel/right-1.png");
  background-size: cover;
  /* background: url("../assets/home-carousel/头图左箭头-选中.png") fixed no-repeat cover; */
  display: inline-block;
  width: 32px;
  height: 51px;
}

.second >>> .el-icon-arrow-right:hover:before {
  background-image: url("../../assets/home-carousel/right-2.png");
}
.second >>> .el-carousel__indicators--horizontal {
  display: none;
}
.second >>> .el-carousel__indicator.is-active button {
  opacity: 1;
  background-color: #cc73ff;
}
/* .swiper-button-prev:after{
    display: none;
}
.swiper-button-next:after{
    display: none;
} */

/* .swiper-button-prev{
    width: 54px;
    height: 54px;
    background: url('../images/left-arrow.svg');
    left: 64px;
} */

/* .swiper-button-next{
    width: 54px;
    height: 54px;
    background:url('../images/right-arrow.svg');
    right: 64px;
} */

.trrr {
  position: relative;
  height: calc(376px);
  /* width: unset; */
  display: flex;
  align-items: flex-end;
  transition: none;
  /* flex-direction: column; */
  /* justify-content: space-between; */
}
.changed {
  /* transition: all 0.02s linear !important; */
}
.trrrd {
  align-items: flex-start !important;
}

.shadow {
  position: absolute;
  right: 0;
  top: 0;
  height: calc(685px + 80px);
  width: 100px;
  background: linear-gradient(to right, transparent, rgba(82, 80, 80, 0.9));
  /* width: calc(642px + 642px + 23px + 23px); */
}

.shadow1 {
  right: unset;
  left: 0;
  background: linear-gradient(to left, transparent, rgba(82, 80, 80, 0.9));
}
/* .second::after{
   content: '';
    flex-grow: 99999;

} */
.click {
  cursor: pointer;
  overflow: hidden;
  position: relative;
  margin-right: 22px;
  /* float: left; */
}

.try {
  transition: all 0.7s ease-in-out;
}

.try:hover {
  /* transform: rotateY(360deg); */
}
.image {
  width: 308px;
  height: 296px;
  overflow: hidden;
}

.image1 {
  width: 343px;
  height: 685px;
}

.image2 {
  width: 638px;
  height: 295px;
  overflow: hidden;
}
.small {
  width: 308px;
  height: calc(80px + 296px);
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}
.long {
  width: 343px;
  height: calc(685px + 80px);
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}
.big {
  width: 638px;
  height: calc(80px + 296px);
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}
.img1 {
  position: absolute;
  opacity: 0;
  left: 0;
  top: 0;
  /* height: 297px; */
  width: 100%;
  transition: all 0.5s ease;
  /* z-index: 7; */
}
.title {
  position: relative;
  /* width: 308px; */
  /* height: 80px; */
  background: #f5f5f7;
  text-align: center;
  overflow: hidden;
}
.title1 {
  width: 638px;
}

.title2 {
  width: 343px;
}
.title-back {
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
  width: 100%;
  height: 80px;
  background-color: transparent;
  text-align: center;
  overflow: hidden;
  font-size: 100px;
  text-transform: uppercase;
  font-family: HarmonyOS_Sans_SC_Black;
  font-weight: 800;
  color: rgba(33, 33, 33, 0);
  opacity: 0.1;
  -webkit-text-stroke: 1px #212121;
  /* text-stroke: 1px #212121; */
  transform: skew(-25deg);
  line-height: 80px;
  letter-spacing: -3px;
}

.title-show {
  /* transform: skew(15deg);  */
  font-size: 24px;
  font-family: HarmonyOS_Sans_SC;
  font-weight: 400;
  color: #212121;
  line-height: 80px;
}

/* .img1 {

  opacity: 0.9;
} */
.img1:hover {
  transform: scale(1.1);
  opacity: 1;
}
</style>