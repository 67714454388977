<template>
  <div class="blackfff" :class="{ xiugai: object }">
    <div class="middle" :class="{ xiugaiMiddle: object }">
      <img src="../../assets/make/news/d.png" v-if="object" class="close" @click="emit" />
      <img src="../../assets/make/news/close.png" v-else class="close" @click="emit" />
      <img src="../../assets/make/news/bg.png" class="back" v-if="!object" />
      <div class="content" :class="{ xiugaiContent: object }">
        <div class="title" v-if="!object">
          <div class="title-a">
            <!-- {{object}} -->
            {{ type === "pic" ? object.name : newcontent.title }}
          </div>
          <div class="title-b" v-if="type !== 'pic'">{{ newcontent.publish_time }}</div>
        </div>
        <img src="../../assets/make/news/jj.png" class="ddddd" />
        <div class="article" :class="{ ffff: object }">
          <div v-if="newcontent && newcontent.content" v-html="newcontent.content"></div>
          <div
            v-if="
              object &&
              object.picture !== '' &&
              object.picture &&
              object.type === 1
            "
          >
            <el-carousel height="100vh" style="width: 100vw">
              <el-carousel-item v-for="(item, index) in object.picture" :key="index">
                <img :src="item.image" class="imgdc" />
              </el-carousel-item>
            </el-carousel>
          </div>
          <div
            class="vidoe"
            v-if="
              object && object.video && object.video !== '' && object.type === 2
            "
          >
            <video :src="object.video" controls="controls" class="video">您的浏览器不支持 video 标签。</video>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ElCarousel, ElCarouselItem } from "element-plus";
export default {
  components: {
    ElCarousel,
    ElCarouselItem,
  },
  props: {
    newcontent: {
      type: Object,
      default: () => { },
    },
    object: {
      type: Object,
      default: () => { },
    },
    type: {
      type: String,
      default: "",
    },
  },

  watch: {
    object: {
      handler(val) {
        console.warn(val, "obje");
      },
      immediate: true,
    },
  },
  data() {
    return {
      img: "",
    };
  },
  mounted() {
    document.body.style = `overflow: hidden;
height: 100%;`;
  },
  beforeUnmount() {
    document.body.style = `overflow: unset;
height: unset;`;
  },
  methods: {
    emtryObject(name) {
      return JSON.stringify(name) === "{}";
    },
    emit() {
      this.$emit("close");
    },
    goba(img) {
      console.warn("11111");
      this.$emit("img", img);
    },
  },
};
</script>
<style scoped>
.video {
  width: 1122px;
  height: 631px;
}

.imgdc {
  /* width: 100%; */
  width: 1333px;
  height: auto;
  cursor: pointer;
}

.ddddd {
  width: 40vw;
}
.blackfff {
  position: fixed;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.45);
}

.xiugai {
  background: url("../../assets/make/news/bacc.png") no-repeat 100% !important;
  background-size: cover !important;
}

.xiugaiMiddle {
  width: 100vw !important;
  height: 100vh !important;
}

.xiugaiContent {
  position: absolute;
  left: 0;
  top: 0;
  display: unset !important;
  width: 100vw !important;
  height: 100vh !important;
}

.content {
  /* width: 100%;
  height: auto; */
  width: 55vw;
  /* height: 95vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1001;
  /* justify-content: center; */
}
.middle {
  width: 55vw;
  /* height: 95vh; */
  /* width: 1104px;
  height: 952px; */
  position: relative;
  /* background-image: url("../../assets/make/新闻浮窗/浮窗bg.png") ;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center; */
  /* background-size: contain; */
}

.back {
  position: absolute;
  left: 0;
  top: 0;
  width: 55vw;
  /* height: ; */
  /* height: 80vw; */
  height: 95vh;
  /* width: 1104px;
  height: 952px; */
  z-index: -1;
}
.title {
  margin-top: 10vh;
  text-align: center;
  margin-bottom: 2vh;
}

.title-a {
  font-size: 30px;
  font-weight: 600;
}

.title-b {
  margin-top: 1vh;
  color: rgba(0, 0, 0, 0.55);
}
.close {
  position: absolute;
  right: 48px;
  top: 42px;
  z-index: 3000;
  cursor: pointer;
}
.article {
  font-size: 16px;
  margin-top: 2.5vh;
  width: 40vw;
  height: 66vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  padding: 0 1vw;
  margin-bottom: 9vh;
}
.article::-webkit-scrollbar {
  /* position: absolute; */
  /* right: 20px; */
  width: 0.5vw;
  /* height: 72px; */
}

.article::-webkit-scrollbar-track {
  background-color: white;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}
.article::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.25);
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}
.ffff {
  position: relative;
  margin-top: 0px !important;
  height: 100vh !important;
  width: 100vw !important;
  width: 100vh !important;
  display: unset !important;
}
.ffff::-webkit-scrollbar {
  display: none !important;
}

.article >>> .el-icon-arrow-left:before {
  content: "";
  /* background-color: teal; */

  background-image: url("../../assets/home-carousel/left-1.png");
  background-size: cover;
  /* background: url("../assets/home-carousel/头图左箭头-选中.png") fixed no-repeat cover; */
  display: inline-block;
  width: 64px;
  height: 102px;
}

.article >>> .el-icon-arrow-left:hover:before {
  background-image: url("../../assets/home-carousel/left-2.png");
  background-size: cover;
}
.article >>> .el-carousel__indicator--horizontal {
  height: 4px;
}
.article >>> .el-icon-arrow-right:before {
  content: "";
  /* background-color: teal; */

  background-image: url("../../assets/home-carousel/right-1.png");
  background-size: cover;
  /* background: url("../assets/home-carousel/头图左箭头-选中.png") fixed no-repeat cover; */
  display: inline-block;
  width: 64px;
  height: 102px;
}
.article >>> .el-carousel__arrow:hover{
  background-color: transparent;
}
.article >>> .el-carousel__arrow--right{
  right: 10.6rem;
}

.article >>> .el-carousel__arrow{
  display: flex;
  justify-content: center;
  align-items: center;
}

.article >>> .el-carousel__arrow--left{
  left: 10.6rem;
}
.article >>> .el-icon-arrow-right:hover:before {
  background-image: url("../../assets/home-carousel/right-2.png");
  background-size: cover;
}

.article >>> .el-carousel__indicator.is-active button {
  opacity: 1;
  background-color: #CC73FF;
}
.vidoe {
  /* position: absolute; */
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ccc {
  font-size: 16px;
}
</style>